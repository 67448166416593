<template>
  <div v-if="isShow" class="wrapper">
    <el-row :gutter="20">
      <el-col :md="8">
        <el-card>
          <appr-next
            :apprNext="apprNext"
            :isShowBTN="isShowBTN"
            :isEffect="isEffect"
            :isShow="isShow"
            :isYongYouFlag="isYongYouFlag"
            :isShowEffect="isShowEffect"
            :isIneffective="isIneffective"
            @getApprObj="getApprObj"
            @showEffect="showEffect"
            @jumpBackToDocumentInformation="$emit('jumpBackToDocumentInformation')"
            v-if="apprNext"
          ></appr-next>
          <div v-else>
            <i class="el-icon-info"></i>
            <span class="vg_ml_8">{{ nextContect }}</span>
          </div>
        </el-card>
      </el-col>
      <el-col :md="16">
        <appr-list :apprList="apprList"></appr-list>
      </el-col>
    </el-row>
  </div>
  <span v-else>单据信息编辑未完成!</span>
</template>

<script>
import { get } from '@api/request';
import { apprAPI } from '@/api/modules/appr';
import apprNext from '@/views/component/apprCommon/apprNext';
import apprList from '@/views/component/apprCommon/apprList';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'apprMain',
  components: {
    apprList,
    apprNext
  },
  data() {
    return {
      apprList: [],
      apprNext: {},
      nextContect: '',
      isShowBTN: false,
      isEffect: false,
      isShowEffect: false,
      isIneffective: false
    };
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    isYongYouFlag: {
      type: Boolean,
      required: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getApprObj();
    },
    // 反生效清除按钮
    showEffect(val) {
      // this.isShowEffect = false;
      // setTimeout(() => {
      //   this.isEffect = false;
      // }, 100);
      // this.$nextTick(()=>{
      //   this.isShowEffect = val;
      // })
    },
    // 获取所有信息
    getApprObj(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let form_id = props.form_id;
      get(apprAPI.getApprInsts, { perm_id: props.perm_id, form_id }).then(res => {
        if (res.data.code === 0) {
          this.apprList = res.data.data.list;
          this.apprNext = res.data.data.next;
          if (this.apprNext) {
            if (this.apprNext.appr_inst_this_node === -1) {
              this.isShowBTN = true;
              this.isEffect = false;
            } else if (this.apprNext.appr_inst_this_node === 0) {
              this.isShowBTN = false;
              this.isEffect = true;
            } else {
              this.isShowBTN = false;
              this.isEffect = false;
            }
          } else {
            this.nextContect = '暂无审批';
          }
        } else if (res.data.code === 2) {
          this.isEffect = true;
          if (res.data.data.status === 0) {
            this.isShowEffect = true;
          } else if (res.data.data.status === 2) {
            this.isShowEffect = false;
            this.apprList = res.data.data.list;
            if (res.data.data.btn === true) {
              this.isIneffective = true;
            } else {
              this.isIneffective = false;
            }
          }
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
      if (val) this.$emit('draftState');
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper .el-row {
  padding: 20px 80px;
}
.el-icon-info {
  color: $color-info;
}
</style>

<template>
  <div class="wrapper-box">
    <div class="vd_head_button" v-if="isShowBTN">
      <el-button @click="subAppr()" type="success" :disabled="btnAble" size="small">提交审批</el-button>
    </div>
    <div class="vd_head_button" v-if="!isShowBTN && !isEffect">
      <el-button size="small" type="success" @click="passAppr()" :disabled="btnAble">通过</el-button>
      <el-button size="small" type="danger" @click="redAppr()" :disabled="btnAble">拒绝</el-button>
    </div>
    <div class="vd_head_button" v-if="isEffect">
      <div class="vd_head_button" v-if="isShowEffect">
        <el-button size="small" type="warning" @click="effectAppr()">生效</el-button>
      </div>
      <div class="vd_head_button" v-if="!isShowEffect">
        <div class="vd_head_button" v-if="isIneffective">
          <el-button size="small" type="danger" @click="ineffectiveAppr()" :disabled="isYongYouFlag">反生效</el-button>
        </div>
        <div v-if="!isIneffective">此单据已生效</div>
      </div>
    </div>
    <div class="vd_select_plane" v-if="showPanle">
      <el-card class="vd_textarea" shadow="none">
        <span> ({{ this.btnCont }})备注：</span>
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
      </el-card>
      <el-card shadow="none">
        <div v-if="isSelPanel">
          <span>选择审批人：</span>
          <el-select v-model="checkDeptId" clearable filterable placeholder="请选择部门" size="small">
            <el-option v-for="item in appr_dept_list" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
          </el-select>
          <el-checkbox-group v-model="checkStffIds">
            <el-checkbox v-for="item in computedCheckList" :key="item.user_id" :label="item.user_id">{{ item.stff_name }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-if="!isSelPanel">
          <i class="el-icon-warning"></i>
          <span class="vd_msg">{{ this.msgContent }}</span>
        </div>
        <div class="vg_button">
          <el-button size="small" @click="closePanel()">取消</el-button>
          <el-button size="small" type="primary" :loading="buttonFlag" @click="submitPa(checkStffIds)">确认</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { apprAPI } from '@api/modules/appr';
import { leavAPI } from '@api/modules/leav';
import bus from '@/components/common/bus';
import UrlEncode from '@assets/js/UrlEncode';
import { podcAPI } from '@api/modules/podc';
import { modcAPI } from '@api/modules/modc';
import { sconAPI } from '@api/modules/scon';
import { podrAPI } from '@api/modules/podr';
import { modrAPI } from '@api/modules/modr';
import { requAPI } from '@api/modules/requ';

export default {
  name: 'apprNext',
  props: {
    apprNext: Object,
    isShow: Boolean,
    isShowBTN: Boolean,
    isEffect: Boolean,
    isIneffective: Boolean,
    isShowEffect: Boolean,
    nextContect: String,
    isYongYouFlag: Boolean //是否已经转入用友
  },
  // inject: ['reload'],
  data() {
    return {
      checkDeptId: null,
      checkStffIds: [],
      stffNameList: [],
      textarea: '',
      showPanle: false,
      btnAble: false,
      btnCont: '',
      apprFormB: {}, // 提交获取的信息
      apprForm: {},
      appr_dept_list: [],
      checkList: [],
      msgContent: '',
      isSelPanel: true,
      stff_id: null,
      buttonFlag: false,
      numFlag: 1
    };
  },
  computed: {
    computedCheckList() {
      if (this.checkDeptId) return this.checkList.filter(x => !this.checkDeptId || x.dept_id === this.checkDeptId);
      else if (this.checkStffIds.length > 0) return this.checkList.filter(x => this.checkStffIds.includes(x.user_id));
      else return this.checkList;
    }
  },
  created() {
    let props = JSON.parse(UrlEncode.decode(this.$route.query.key));
    if (props.perm_id === '109') {
      this.getLeavInfo(props.form_id);
    }
  },
  mounted() {},
  methods: {
    refreshTheApprovalListOnTheHomepage() {
      bus.$emit('refreshTheApprovalListOnTheHomepage', '');
    },
    jumpBackToDocumentInformation() {
      this.$emit('jumpBackToDocumentInformation');
    },
    // 提交
    subAppr() {
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.apprForm.appr_inst_this_node = -1;
      this.apprForm.appr_inst_action = 1;
      this.btnCont = '提交';
      this.getStffList();

      // this.getSubPer()
    },
    // 通过
    passAppr() {
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.apprForm.appr_inst_action = 2;
      this.btnCont = '通过';
      this.getStffList();
    },
    // 拒绝
    redAppr() {
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.btnCont = '拒绝';
      this.apprForm.appr_inst_action = 3;
      this.getStffList();
    },
    // 生效按钮
    effectAppr() {
      if (this.isShow) {
        this.$confirm('生效后不可反生效，是否确认生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await this.effect();
            await this.jumpBackToDocumentInformation();
            await this.refreshTheApprovalListOnTheHomepage();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生效'
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '单据信息编辑未完成'
        });
      }
    },
    // 反生效按钮
    ineffectiveAppr() {
      if (this.isShow) {
        this.$confirm('请确定是否反生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await this.ineffective();
            await this.jumpBackToDocumentInformation();
            await this.refreshTheApprovalListOnTheHomepage();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消反生效'
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '单据信息编辑未完成'
        });
      }
    },
    // 获取审批人
    getStffList() {
      const nodeBody = {};
      nodeBody.perm_id = this.apprForm.perm_id;
      nodeBody.form_id = this.apprForm.form_id;
      nodeBody.appr_inst_this_node = this.apprForm.appr_inst_this_node;
      nodeBody.appr_inst_action = this.apprForm.appr_inst_action;
      get(apprAPI.getApprInstNextNode, nodeBody).then(res => {
        if (res.data.code === 0) {
          this.apprFormB = res.data.data;
          this.checkList = this.apprFormB.appr_node_user_list || [];
          //根据模块处理自定义需求
          switch (nodeBody.perm_id) {
            case 112:
              //张绍平	设计部 提出 ：委托设计单默认勾选审批人 2024年6月11日14:47:31
              this.checkList.forEach(item => {
                if (item.user_id === 7) {
                  this.checkStffIds.push(item.user_id); //史静颖
                }
                if (item.user_id === 68) {
                  this.checkStffIds.push(item.user_id); //张绍平
                }
                if (item.user_id === 60) {
                  this.checkStffIds.push(item.user_id); //郑颖
                }
                if (item.user_id === 61) {
                  this.checkStffIds.push(item.user_id); //王燕
                }
              });
              break;
            case 135:
              getNoCatch(podcAPI.getPodcById, { podc_id: nodeBody.form_id }).then(({ data }) => {
                let find = this.checkList.find(x => x.stff_name === data.form.cust_stff_name);
                this.checkStffIds.push(find.user_id); // 当前销售经办人
              });
              break;
            case 136:
              getNoCatch(modcAPI.getModcById, { modc_id: nodeBody.form_id }).then(({ data }) => {
                let find = this.checkList.find(x => x.stff_name === data.form.cust_stff_name);
                this.checkStffIds.push(find.user_id); // 当前销售经办人
              });
              break;
            case 126:
              //销售合同
              get(sconAPI.getSconById, { scon_id: nodeBody.form_id })
                .then(res => {
                  if (res.data.code === 0) {
                    let sconForm = res.data.data.form;
                    let find = this.checkList.find(x => x.stff_name === sconForm.cust_stff_name);
                    this.checkStffIds.push(find.user_id); // 当前外销经办人
                  }
                })
                .catch(err => console.log(err));
              break;
            case 127:
              //成品采购合同
              get(podrAPI.getPodrById, { podr_id: nodeBody.form_id })
                .then(res => {
                  if (res.data.code === 0) {
                    let podrForm = res.data.data.form;
                    let find = this.checkList.find(x => x.stff_name === podrForm.podr_stff_name);
                    this.checkStffIds.push(find.user_id); // 当前采购经办人
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch(err => console.log(err));
              break;
            case 128:
              //材料采购合同
              get(modrAPI.getModrById, { modr_id: nodeBody.form_id })
                .then(res => {
                  if (res.data.code === 0) {
                    let modrForm = res.data.data.form;
                    let find = this.checkList.find(x => x.stff_name === modrForm.modr_stff_name);
                    this.checkStffIds.push(find.user_id); // 当前采购经办人
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch(err => console.log(err));
              break;
            case 181:
              //销售合同变更单
              get(sconAPI.getSconOrderById, { sconorder_id: nodeBody.form_id })
                .then(res => {
                  if (res.data.code === 0) {
                    let sconForm = res.data.data.form;
                    let find = this.checkList.find(x => x.stff_name === sconForm.cust_stff_name);
                    this.checkStffIds.push(find.user_id); // 当前外销经办人
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch(err => console.log(err));
              break;
            case 114:
              get(requAPI.getRequById, { requ_id: nodeBody.form_id })
                .then(res => {
                  if (res.data.code === 0) {
                    let dataForm = res.data.data.form;
                    let find = this.checkList.find(x => x.stff_name === dataForm.requ_taker_name);
                    this.checkStffIds.push(find.user_id); // 当前外销经办人
                    return;
                  }
                  this.$message.error(res.data.msg);
                })
                .catch(err => console.log(err));
              break;
          }
          this.appr_dept_list = this.apprFormB.appr_dept_list;
          if (this.apprFormB.appr_node_id === -1) {
            this.isSelPanel = false;
            this.msgContent = '确认后单据将退回到草拟状态!';
          } else if (this.apprFormB.appr_node_id === 0) {
            this.isSelPanel = false;
            this.msgContent = '确认后单据将变成为生效状态!';
          } else {
            this.isSelPanel = true;
          }
        } else {
          let mg = res.data.msg;
          this.isSelPanel = false;
          this.msgContent = mg;
        }
      });
    },
    // 关闭面板
    closePanel() {
      this.btnAble = false;
      this.showPanle = false;
    },
    // 提交选择人
    submitPa(checkStffIds) {
      if (this.btnCont === '拒绝' && !this.textarea) return this.$message.warning('拒绝时备注必填!');
      this.buttonFlag = true;
      this.numFlag = 1;
      if (this.isSelPanel) {
        if (checkStffIds.length === 0) {
          this.buttonFlag = false;
          this.$message({ type: 'warning', message: '请至少勾选一个审核人员！' });
        } else {
          if (this.numFlag === 1) {
            this.numFlag = 2;
            this.saveInfo();
          }
        }
      } else {
        if (this.numFlag === 1) {
          this.isSelPanel = true;
          this.numFlag = 2;
          this.saveInfo();
        }
        // this.saveInfo()
      }
    },
    // 保存选择人挂api
    saveInfo() {
      this.apprForm.appr_inst_remark = this.textarea;
      this.apprForm.next_user_id_list = this.checkStffIds;
      this.apprForm.appr_inst_next_node = this.apprFormB.appr_node_id;
      post(apprAPI.Approve, this.apprForm).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          this.checkStffIds = [];
          this.$emit('getApprObj', true);
          this.btnAble = false;
          this.showPanle = false;
          this.buttonFlag = false;
          this.numFlag = 1;
          this.jumpBackToDocumentInformation();
          this.refreshTheApprovalListOnTheHomepage();
        } else {
          this.buttonFlag = false; //确认按钮loading
          this.isSelPanel = false; //是否显示选择审批人
          let msg = res.data.msg;
          let q = 'error';
          this.$message({ type: q, message: msg });
        }
      });
    },
    // 直接生效
    effect() {
      const nodeBody = {};
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      nodeBody.perm_id = props.perm_id;
      nodeBody.form_id = props.form_id;
      post(apprAPI.makeEffective, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.info(res.data.msg);
            this.$emit('getApprObj', true);
            this.btnAble = false;
            this.showPanle = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '生效失败'
          });
        });
    },
    // 反生效
    ineffective() {
      const nodeBody = {};
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      nodeBody.perm_id = props.perm_id;
      nodeBody.form_id = props.form_id;
      post(apprAPI.makeIneffective, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.btnAble = false;
            this.showPanle = false;
            this.isSelPanel = true;
            this.$emit('showEffect', true);
            this.$emit('getApprObj', true);
            // this.reload()
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        });
    },
    getLeavInfo(form_id) {
      get(leavAPI.getLeavById, { leav_id: form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.stff_id = res.data.data.form.leav_stff_id;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getSubPer() {
      get(leavAPI.getLeavApprUserIds, { stff_id: this.stff_id })
        .then(res => {
          if (res.data.code === 0) {
            let users = res.data.data.user_ids;
            let newArr = [];
            users.forEach(item => {
              this.checkList.forEach(item2 => {
                if (item === item2.user_id) {
                  newArr.push(item2);
                }
              });
            });
            this.checkList = newArr;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.message);
        });
    }
  }
};
</script>

<style scoped lang="scss">
//.wrapper ::v-deep .el-card{
//  color:#969696
//}
.wrapper-box {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.vd_textarea {
  margin-bottom: 5px;
  .el-textarea {
    margin-top: 5px;
  }
}
.vd_select_plane {
  height: 100%;
  margin-top: 5px;
  max-width: 400px;
  min-height: 200px;
  position: relative;
  animation: mymove 0.2s forwards;
  animation-delay: 0.2s;
  .el-checkbox-group {
    margin-top: 5px;
  }
}
@keyframes mymove {
  from {
    top: 0px;
  }
  to {
    top: 20px;
  }
}

.vg_button {
  display: flex;
  justify-content: flex-end;
}
.vd_button {
  display: flex;
  justify-content: right;
}
.el-icon-warning {
  color: $color-deep-red;
}
.vd_msg {
  margin-left: 5px;
  font-weight: 700;
}
.vd_head_button {
  transition: ease-in-out 0.4s;
}
</style>
